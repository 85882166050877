import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import propTypes from 'prop-types';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Alert from '@mui/material/Alert';

import { getForm, addRow } from '../../http';
import BenutzerContext from '../../BenutzerContext';
import { tabellen } from '../../constants';

import {
  Button,
  ContentBox,
  Input,
  Loading,
} from '../Common';

import NewKundeForm from './NewKundeForm';

function initialValues(form, defaults) {
  const values = {};
  Object.keys(form).forEach((key) => {
    if (defaults[key] === undefined
      && ['id', 'uuid'].includes(key)) {
      return;
    }
    if (form[key].readOnly || form[key].locked) return;

    if (defaults[key] !== undefined) { values[key] = defaults[key]; return; }
    if (form[key].default !== undefined) { values[key] = form[key].default; return; }
    values[key] = '';
  });
  return values;
}

function getDependencyValues(form, values) {
  const dependencyValues = {};
  const keys = Object.keys(form);
  keys.forEach((key) => {
    const { dependsOn } = form[key];
    if (!dependsOn) return;
    dependencyValues[dependsOn] = values[dependsOn];
  });
  return dependencyValues;
}

function ErrorMessage({ text }) {
  if (!text) return <div className="add-row-error" />;
  return (
    <div className="add-row-error">
      <Alert
        severity="error"
        variant="outlined"
        sx={{ width: 'auto' }}
      >
        {text}
      </Alert>
    </div>
  );
}
ErrorMessage.propTypes = { text: propTypes.string };
ErrorMessage.defaultProps = { text: '' };

function InputForm({
  table,
  form,
  setForm,
  validationError,
  validate,
  onSubmit,
  setFieldError,
}) {
  const [values, setValues] = useState(initialValues(form, {}));
  const fieldsPerRow = 2;
  const selectNewKunde = (id, name) => {
    values.kunde_id = id;
    const { choices } = form.kunde_id;
    choices[id] = name;
    setValues({ ...values });
    setForm({ ...form });
  };
  const stringDependencyValues = JSON.stringify(getDependencyValues(form, values));

  useEffect(() => { validate(values); }, [values]);

  const fields = useMemo(() => {
    const timestamp = Date.now();
    const inputRows = [];
    Object.keys(form)
      .filter((key) => !form[key].locked)
      .filter((key) => !form[key].readOnly)
      .forEach((key, index) => {
        const elementKey = `${key}-${timestamp}`;
        const field = (
          <Input
            key={elementKey}
            label={form[key].label}
            id={key}
            value={values[key]}
            onChange={(newValue) => {
              values[key] = newValue;
              setValues({ ...values });
            }}
            type={form[key].inputType || 'text'}
            disabled={Boolean(form[key].dependsOn
              && !values[form[key].dependsOn])}
            choices={form[key].dependsOn
              ? form[key].choices[values[form[key].dependsOn]]
              : form[key].choices}
            required={Boolean(form[key].required)}
            reportError={(newError) => { setFieldError(key, newError); }}
          />
        );
        if (index % fieldsPerRow === 0) inputRows.push([field]);
        else inputRows[inputRows.length - 1].push(field);
        if (table === 'Preisliste' && key === 'artikel_or_option_id') {
          inputRows.push([
            (
              <Link to="/Artikeloptionen/add" state={{ saveLink: '/Preisliste/add' }}>
                <Button variant="outlined" fullWidth>Artikeloption erstellen</Button>
              </Link>
            ),
            (
              <Link to="/Artikel/add" state={{ saveLink: '/Preisliste/add' }}>
                <Button variant="outlined" fullWidth>Artikel erstellen</Button>
              </Link>
            ),
          ]);
        }
      });
    return inputRows;
  }, [form, stringDependencyValues]);

  return (
    <ContentBox style={{ width: '100%' }}>
      <Link to={`/${table}`}>
        <Button>
          <ArrowLeftIcon />
          {`Übersicht ${table}`}
        </Button>
      </Link>
      <NewKundeForm table={table} selectNewKunde={selectNewKunde} />
      <ErrorMessage text={validationError} />
      <form>
        {fields.map((row) => (
          <div
            key={row[0].key}
            style={{
              maxWidth: `${(100 / fieldsPerRow) * row.length}%`,
            }}
          >
            {row}
          </div>
        ))}
        <Button
          variant="contained"
          onClick={() => { onSubmit(values); }}
          disabled={Boolean(validationError)}
        >
          {tabellen[table].postSubmitMessage || 'Speichern'}
        </Button>
      </form>
    </ContentBox>
  );
}
InputForm.propTypes = {
  table: propTypes.string.isRequired,
  form: propTypes.instanceOf(Object).isRequired,
  setForm: propTypes.func.isRequired,
  validationError: propTypes.string.isRequired,
  validate: propTypes.func.isRequired,
  onSubmit: propTypes.func.isRequired,
  setFieldError: propTypes.func.isRequired,
};

const states = {
  loading: 0,
  input: 1,
  failure: 2,
  success: 3,
};
function AddRow() {
  const { form: stateForm, saveLink } = useLocation().state || {};
  const { table } = useParams();
  const { schluessel: userKey } = useContext(BenutzerContext);
  const [form, setForm] = useState(stateForm);
  const [validationError, setValidationError] = useState('');
  const [warning, setWarning] = useState('');
  const [state, setState] = useState(states.loading);
  const [erroredFields, setErroredFields] = useState([]);

  const setFieldError = (fieldName, error) => {
    if (error) {
      if (erroredFields.includes(fieldName)) return;
      erroredFields.push(fieldName);
      setErroredFields([...erroredFields]);
    } else {
      if (!erroredFields.includes(fieldName)) return;
      setErroredFields(erroredFields.filter((name) => name !== fieldName));
    }
  };

  const validate = (values) => {
    for (let i = 0; i < Object.keys(form).length; i += 1) {
      if (i === 0) {
        setValidationError('');
      }

      const key = Object.keys(form)[i];

      const badValue = (val) => {
        if (form[key].locked) return false;

        if (form[key].inputType === 'datetime-local') {
          if (val.includes('undefined') || val.trim().length === 0) return 'Sowohl Datum und Zeit sind erforderlich.';

          const beforeNow = (datetime) => new Date(datetime) < new Date();
          if (beforeNow(val)) return 'Bitte wählen eine spätere Zeit oder Datum aus.';
          return false;
        }

        if (form[key].required && !val) return `${form[key].label || key} ist erforderlich.`;
        if (!form[key].required && !val) return false;

        return false;
      };
      const errorMessage = badValue(values[key]);

      if (errorMessage) setValidationError(errorMessage);
    }

    return true;
  };
  const onSubmit = (values) => {
    if (!validate(values)) return;
    const hasFile = table === 'Einstellungen';
    setState(states.loading);
    addRow(table, userKey, values, hasFile)
      .then(() => { setState(states.success); })
      .catch(({ response, message }) => {
        setWarning(response.data || message || 'Es gab ein Problem.');
        setState(states.failure);
      });
  };

  useEffect(() => {
    if (form) {
      setState(states.input);
    } else {
      getForm(table, userKey)
        .then((data) => { setForm(data); })
        .catch(({ response, message }) => {
          setWarning(response.data || message || 'Es gab ein Problem.');
          setState(states.failure);
        });
    }
  }, [form]);

  useEffect(() => {
    setForm();
    setState(states.loading);
  }, [table]);

  switch (state) {
    case states.failure:
      return (
        <ContentBox>
          {warning}
        </ContentBox>
      );
    case states.success:
      return (
        <ContentBox style={{ textAlign: 'center' }}>
          {tabellen[table].postSuccessMessage
            || `${tabellen[table].singular || table} erfolgreich erstellt.`}
          <br />
          <br />
          {saveLink
            ? (
              <Link to={saveLink}>
                <Button variant="outlined">Zurück</Button>
              </Link>
            ) : (
              <Link to={`/${table}`}>
                <Button variant="outlined">{`Übersicht ${table}`}</Button>
              </Link>
            )}
        </ContentBox>
      );
    case states.input:
      return (
        <InputForm
          table={table}
          form={form}
          setForm={setForm}
          validationError={validationError}
          validate={validate}
          onSubmit={onSubmit}
          setFieldError={setFieldError}
        />
      );
    default:
      return (
        <ContentBox style={{ textAlign: 'center' }}>
          <Loading />
        </ContentBox>
      );
  }
}

export default AddRow;
