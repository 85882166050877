import axios from 'axios';

import { paletteColors } from '../constants';
import translate from '../translations';

function formURL(table, query = '') {
  const host = process.env.REACT_APP_BACKEND_URL
    .replaceAll('http://', '')
    .replaceAll('https://', '');
  const protocol = 'https://';
  const clean = (url) => url.replaceAll('//', '/');

  const url = protocol.concat(
    clean(query
      ? `${host}/${table}/${query}`
      : `${host}/${table}`),
  );
  return url;
}

const getForm = (table, key) => new Promise((resolve, reject) => {
  axios.get(
    formURL(table, 'form'),
    { params: { key } },
  )
    .then(({ data }) => {
      const newForm = data;
      if (newForm.farbcode) {
        paletteColors.forEach((name) => {
          newForm.farbcode.choices[name] = translate(name);
        });
      }
      resolve(newForm);
    })
    .catch((err) => { reject(err); });
});

const getRows = (table, key) => axios.get(formURL(table), { params: { key } });

const getRow = (table, key, id) => new Promise((resolve, reject) => {
  axios.get(formURL(table), { params: { key, id } })
    .then(({ data }) => {
      data.forEach((row) => {
        if (`${row.id}` === `${id}`) resolve(row);
        if (`${row.uuid}` === `${id}`) resolve(row);
        if (!row.id
          && !row.uuid
          && row.unternehmen_id
          && `${row.unternehmen_id}` === `${id}`) { resolve(row); }
      });
    })
    .catch((err) => { reject(err); });
});

function stringToNull(params) {
  const newParams = {};
  Object.keys(params).forEach((key) => {
    if (params[key] === 'null') return;
    newParams[key] = params[key];
  });
  return newParams;
}

const addRow = (table, key, params, fileFields) => new Promise((resolve, reject) => {
  const formData = new FormData();
  const hasFile = fileFields && fileFields.length > 0;
  if (hasFile) {
    const keys = Object.keys(params);
    formData.append('key', key);
    keys.forEach((param) => {
      const value = params[param];
      if (value === 'null') return;
      if (fileFields.includes(param)) {
        const { name } = value;
        formData.append(param, value, name);
      } else {
        formData.append(param, value);
      }
    });
  }

  axios.post(
    formURL(table),
    hasFile ? formData : { key, params: stringToNull(params) },
    hasFile ? { headers: { 'Content-Type': 'multipart/form-data' } } : undefined,
  ).then(({ data }) => { resolve(data); })
    .catch((err) => { reject(err); });
});

const editRow = (table, key, data, fileFields) => new Promise((resolve, reject) => {
  const formData = new FormData();
  const hasFile = fileFields && fileFields.length > 0;
  if (hasFile) {
    const keys = Object.keys(data);
    formData.append('key', key);
    keys.forEach((param) => {
      const value = data[param];
      if (value === 'null') return;
      if (fileFields.includes(param)) {
        const { name } = value;
        formData.append(param, value, name);
      } else {
        formData.append(param, value);
      }
    });
  }

  axios.put(
    formURL(table),
    hasFile ? formData : { key, data: stringToNull(data) },
    hasFile ? { headers: { 'Content-Type': 'multipart/form-data' } } : undefined,
  )
    .then(({ data: newRow }) => { resolve(newRow); })
    .catch((err) => { reject(err); });
});

const deleteRow = (table, key, id) => new Promise((resolve, reject) => {
  axios.delete(formURL(table), { data: { key, id } })
    .then((data) => { resolve(data); })
    .catch((err) => { reject(err); });
});

export {
  getForm,
  getRows,
  getRow,
  addRow,
  editRow,
  deleteRow,
};
