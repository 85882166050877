import React, { useState } from 'react';
import {
  Link,
  useParams,
  useNavigate,
} from 'react-router-dom';
import axios from 'axios';
import Typography from '@mui/material/Typography';

import Button from '../Common/Button';
import ContentBox from '../Common/ContentBox';
import Input from '../Common/Input';
import Loading from '../Common/Loading';

function Reset() {
  const { code } = useParams();
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();
  const match = password1 === password2;

  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_BACKEND_URL;
  const onSubmitEmail = () => {
    setLoading(true);
    axios.get(
      `${baseURL}/reset`,
      { params: { email } },
    ).then(() => { setLoading(false); setEmailSent(true); });
  };
  const onSubmitPassword = () => {
    axios.get(
      `${baseURL}/reset`,
      { params: { code, newPassword: password1 } },
    ).then(() => { navigate('/signin'); });
  };

  if (loading) return (<ContentBox><Loading /></ContentBox>);
  if (emailSent) {
    return (
      <ContentBox>
        <Typography variant="h2">
          Das Passwort wurde zurückgesetzt!
        </Typography>
        <Typography>
          Wir haben Ihnen per E-Mail einen Link zugeschickt,
          womit Sie Ihr Passwort neu setzen können.
          Bitte prüfen Sie ggf. auch ihren SPAM-Ordner.
        </Typography>
      </ContentBox>
    );
  }
  if (code) {
    return (
      <ContentBox>
        <Typography variant="h2">
          Neues Passwort erstellen
        </Typography>
        <form>
          <Input
            id="reset-password-1"
            label="Neues Passwort eingeben"
            type="password"
            value={password1}
            onChange={(newValue) => { setPassword1(newValue); }}
          />
          <Input
            id="reset-password-2"
            label="Neues Passwort wiederholen"
            type="password"
            value={password2}
            onChange={(newValue) => { setPassword2(newValue); }}
          />
          {!match && <div style={{ textAlign: 'center' }}>Passwords do not match</div>}
          <Button
            variant="contained"
            fullWidth
            onClick={onSubmitPassword}
          >
            Speichern
          </Button>
        </form>
      </ContentBox>
    );
  }
  return (
    <ContentBox>
      <Typography variant="h2">
        Passwort zurücksetzen
      </Typography>
      <form>
        <Input
          id="reset-email"
          label="E-Mail-Adresse"
          type="email"
          value={email}
          onChange={(newValue) => {
            setEmail(newValue);
          }}
        />
        <Button
          onClick={onSubmitEmail}
          variant="contained"
          fullWidth
        >
          Passwort zurücksetzen
        </Button>
        <br />
        <br />
        <Link to="/SignIn">
          <Button fullWidth>{'< Einloggen'}</Button>
        </Link>
      </form>
    </ContentBox>
  );
}

export default Reset;
