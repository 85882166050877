import React from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import FactoryIcon from '@mui/icons-material/Factory';
import GroupsIcon from '@mui/icons-material/Groups';
import StoreIcon from '@mui/icons-material/Store';
import SettingsIcon from '@mui/icons-material/Settings';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import TuneIcon from '@mui/icons-material/Tune';
import EuroIcon from '@mui/icons-material/Euro';
import { ReactComponent as EarbudsIcon } from './components/Icons/Otoplastik.svg';

export const primaryColorCode = '#6e7a58';
export const paletteColors = [
  'blue',
  'indigo',
  'purple',
  'pink',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'cyan',
];

export const colors = {
  blue: '#007bff',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#e83e8c',
  red: '#dc3545',
  orange: '#fd7e14',
  yellow: '#ffc107',
  green: '#28a745',
  teal: '#20c997',
  cyan: '#17a2b8',
  white: '#fff',
  gray: '#6c757d',
  grayDark: '#343a40',
  primary: primaryColorCode,
  secondary: '#6c757d',
  success: '#28a745',
  info: '#17a2b8',
  warning: '#ffc107',
  danger: '#dc3545',
  light: '#f8f9fa',
  dark: '#343a40',
};

const categories = {
  Kunden: [
    'Kunden',
    'Einladungen',
    'Auswertungen',
  ],
  Administration: [
    'Krankenkassen',
    'Hersteller',
  ],
  Einstellungen: [
    'Mitarbeiter',
    'Unternehmen',
    'Einstellungen',
    'Statusstufen',
  ],
  Preisliste: [
    'Artikel',
    'Artikeloptionen',
    'Otoplastiken',
    'Preisliste',
  ],
};

export const tabellen = {
  Unternehmen: {
    color: colors.primary,
    icon: <StoreIcon />,
    gender: 'neuter',
  },
  Mitarbeiter: {
    color: colors.primary,
    icon: <GroupsIcon />,
    gender: 'masculine',
  },
  Kunden: {
    color: colors.primary,
    singular: 'Kunde',
    icon: <PeopleAltIcon />,
    gender: 'masculine',
  },
  Krankenkassen: {
    color: colors.primary,
    singular: 'Krankenkasse',
    icon: <LocalPharmacyIcon />,
    gender: 'feminine',
  },
  Hersteller: {
    color: colors.primary,
    icon: <FactoryIcon />,
    gender: 'masculine',
  },
  Artikel: {
    color: colors.primary,
    textColor: 'white',
    icon: <HeadphonesIcon />,
    gender: 'masculine',
  },
  Artikeloptionen: {
    icon: <TuneIcon />,
    singular: 'Artikeloption',
    gender: 'feminine',
  },
  Preisliste: {
    icon: <EuroIcon />,
    singular: 'Preis',
    gender: 'masculine',
  },
  Einstellungen: {
    icon: <SettingsIcon />,
    gender: 'feminine',
  },
  Einladungen: {
    icon: <CalendarTodayIcon />,
    color: colors.primary,
    textColor: 'white',
    singular: 'Einladung',
    postSubmitMessage: 'Einladung speichern und versenden',
    postSuccessMessage: 'Einladung wurde erfolgreich verschickt.',
    gender: 'feminine',
  },
  Auswertungen: {
    color: colors.primary,
    textColor: 'white',
    singular: 'Auswertung',
    icon: <AssessmentIcon />,
    gender: 'feminine',
  },
  Otoplastiken: {
    color: colors.primary,
    textColor: 'white',
    singular: 'Otoplastik',
    icon: <EarbudsIcon />,
    gender: 'feminine',
  },
  Statusstufen: {
    singular: 'Statusstufe',
  },
};

export const defaultColumns = {
  Unternehmen: ['id', 'address', 'parentID', 'name', 'email'],
  Mitarbeiter: ['id', 'name', 'email', 'unternehmen_id'],
  Kunden: [
    'anrede',
    'name',
    'address',
    'telefonnummer',
    'email',
  ],
  Krankenkassen: [
    'krankenkassennummber',
    'name',
    'address',
    'telefonnummer',
    'email',
  ],
  Hersteller: [
    'id',
    'name',
    'address',
    'telefonnummer',
    'email',
  ],
  Artikel: ['hersteller_id', 'artikelnummber', 'artikelbezeichnung', 'produktgruppe'],
  Artikeloptionen: [
    'artikel_id',
    'bezeichnung',
    'preisliste',
    'rabatt',
  ],
  Preisliste: ['unternehmen_id', 'artikel_id', 'hauspreis', 'rabatt'],
  Einstellungen: ['unternehmen_id', 'unternehmensfarbcode', 'text_farbe', 'highlight_farbe', 'schriftart', 'logo'],
  Einladungen: [
    'mitarbeiter_id',
    'kunde_id',
    'meeting_time',
  ],
  Auswertungen: [
    'mitarbeiter_id',
    'kunde_id',
    'mitarbeiter_hash',
    'last_updated',
    'auswertung_status',
  ],
};

export default categories;
