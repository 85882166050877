import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import BenutzerContext from '../../BenutzerContext';

import Button from '../Common/Button';
import ContentBox from '../Common/ContentBox';
import Input from '../Common/Input';

const STRINGS = {
  authFailure: 'Konnte nicht einloggen.',
  submit: 'Einloggen',
};

const FORM = {
  email: {
    label: 'E-Mail',
    type: 'email',
    value: '',
  },
  passwort: {
    label: 'Passwort',
    type: 'password',
    value: '',
  },
};

function SignIn() {
  const {
    setSchluessel,
    storeKey,
    setUserName,
    setCompanyName,
  } = useContext(BenutzerContext);
  const [form] = useState(JSON.parse(JSON.stringify(FORM)));
  const [response, setResponse] = useState('');
  const [staySignedIn, setStaySignedIn] = useState(storeKey);
  const navigate = useNavigate();

  const submit = () => {
    const params = {};
    Object.keys(form).forEach((key) => { params[key] = form[key].value; });
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/signin`, { params })
      .then(({ data }) => {
        localStorage.setItem('userName', data.userName);
        setUserName(data.userName);

        localStorage.setItem('companyName', data.companyName);
        setCompanyName(data.companyName);

        localStorage.setItem('schluessel', data.key);
        setSchluessel(data.key, staySignedIn);
        navigate('/');
      })
      .catch(() => {
        setResponse(STRINGS.authFailure);
      });
  };

  return (
    <ContentBox>
      <Typography
        variant="h2"
      >
        Willkommen zurück!
      </Typography>
      <form>
        {Object.keys(form).map((key) => (
          <Input
            key={key}
            label={form[key].label}
            id={`signin-${key}`}
            type={form[key].type}
            onChange={(newValue) => { form[key].value = newValue; }}
          />
        ))}
        <Input
          type="checkbox"
          label="Angemeldet bleiben"
          id="angemelded-bleiben"
          checked={staySignedIn}
          onChange={() => { setStaySignedIn(!staySignedIn); }}
        />
        <div
          style={{
            display: 'block',
            width: 'fit-content',
            lineHeight: '1.4375em',
            margin: '10px auto',
          }}
        >
          <Link to="/reset">Passwort vergessen?</Link>
        </div>
        <Button
          fullWidth
          variant="contained"
          onClick={submit}
        >
          {STRINGS.submit}
        </Button>
      </form>
      <br />
      {response && (
        <Alert variant="outlined" severity="error">
          {response}
        </Alert>
      )}
    </ContentBox>
  );
}

export { STRINGS, FORM };
export default SignIn;
