import React from 'react';
import propTypes from 'prop-types';

import ButtonGroup from '@mui/material/ButtonGroup';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { Button, Input } from '../Common';

function FilterPanel({
  open,
  setOpen,
  fields,
  applyFilter,
  resetFilters,
}) {
  const classNames = ['filter-panel', (open ? 'open' : 'closed')];

  return (
    <div className={classNames.join(' ')}>
      <div className="heading">
        <Typography variant="h5">Tabelle filtern</Typography>
        <Button onClick={() => { setOpen(false); }}><CloseIcon /></Button>
      </div>
      <div className="options">
        {fields.map((field) => (
          <Input
            key={field.id}
            id={field.id}
            label={field.name}
            choices={field.choices}
            type="select"
            value={field.selection}
            onChange={(value) => {
              applyFilter(field.id, value);
            }}
          />
        ))}
      </div>
      <ButtonGroup>
        <Button onClick={resetFilters}>Filter zurücksetzen</Button>
      </ButtonGroup>
    </div>
  );
}
FilterPanel.propTypes = {
  open: propTypes.bool,
  setOpen: propTypes.func.isRequired,
  fields: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      name: propTypes.string,
      choices: propTypes.instanceOf(Object),
      selection: propTypes.oneOfType([
        propTypes.string,
        propTypes.number,
      ]),
    }),
  ).isRequired,
  applyFilter: propTypes.func.isRequired,
  resetFilters: propTypes.func.isRequired,
};
FilterPanel.defaultProps = {
  open: true,
};

export default FilterPanel;
