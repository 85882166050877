import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';

import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import LaunchIcon from '@mui/icons-material/Launch';

import Context from '../../BenutzerContext';
import { getForm, getRows } from '../../http';
import { tabellen } from '../../constants';

import { Loading } from '../Common';
import getCell from '../Table/getCell';

function OverviewTable({
  rows,
  form,
  columnIDs,
}) {
  const formKeys = Object.keys(form);
  const columns = columnIDs
    .filter((id) => formKeys.includes(id))
    .map((id) => ({ id, name: form[id].label }));

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ id, name }) => (
              <TableCell key={id} style={{ textAlign: 'left' }}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id
                || row.uuid
                || row.hash
                || row.unternehmen_id}
            >
              {columns.map(({ id }) => (
                <TableCell key={id}>
                  {getCell(form, id, row[id])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
OverviewTable.propTypes = {
  rows: propTypes.arrayOf(propTypes.instanceOf(Object)).isRequired,
  form: propTypes.instanceOf(Object).isRequired,
  columnIDs: propTypes.arrayOf(propTypes.string).isRequired,
};

function Overview({
  xs,
  sm,
  md,
  title,
  tableName,
  tableFilter,
  tableSort,
  columnIDs,
  countOnly,
  maxRows,
}) {
  const { schluessel: key } = useContext(Context);
  const [rows, setRows] = useState();
  const [form, setForm] = useState();

  useEffect(() => {
    if (!key) return;
    getForm(tableName, key)
      .then((data) => { setForm(data); })
      .catch(() => { setForm({}); });
    getRows(tableName, key)
      .then(({ data }) => {
        setRows(
          data
            .filter(tableFilter)
            .sort(tableSort)
            .slice(0, maxRows),
        );
      }).catch(() => { setRows([]); });
  }, [key]);

  if (rows === undefined || rows === null || !form) {
    return (
      <Grid item xs={xs} sm={sm} md={md}>
        <Card variant="outlined">
          <Loading />
        </Card>
      </Grid>
    );
  }

  const renderedTitle = countOnly
    ? `${title}: ${rows.length}`
    : title;

  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <Card
        variant="outlined"
      >
        <CardHeader
          avatar={tabellen[tableName].icon}
          title={renderedTitle}
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
          }}
          action={(
            <Link to={tableName}>
              <IconButton sx={{ color: 'primary.contrastText' }}>
                <LaunchIcon />
              </IconButton>
            </Link>
          )}
        />
        {!countOnly && (
          <CardContent>
            <OverviewTable
              rows={rows}
              form={form}
              columnIDs={columnIDs}
            />
          </CardContent>
        )}
      </Card>
    </Grid>
  );
}
Overview.propTypes = {
  xs: propTypes.number,
  sm: propTypes.number,
  md: propTypes.number,
  title: propTypes.string,
  tableName: propTypes.string,
  tableFilter: propTypes.func,
  tableSort: propTypes.func,
  columnIDs: propTypes.arrayOf(propTypes.string),
  countOnly: propTypes.bool,
  maxRows: propTypes.number,
};
Overview.defaultProps = {
  xs: undefined,
  sm: undefined,
  md: undefined,
  title: undefined,
  tableName: '#',
  tableFilter: () => true,
  tableSort: undefined,
  columnIDs: [],
  countOnly: false,
  maxRows: 8,
};

export default Overview;
