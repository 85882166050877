import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';
import {
  Navigate,
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import BenutzerContext from '../../BenutzerContext';
import {
  Button,
  ContentBox,
  Input,
  Loading,
} from '../Common';
import RowButtons from '../RowButtons';

import {
  getForm,
  getRow,
  editRow,
  deleteRow,
} from '../../http';

import getCell from '../Table/getCell';

const STATES = {
  confirm: 0,
  pending: 1,
  complete: 2,
  cancelled: 3,
  error: 4,
  edit: 5,
};

const MODES = [
  'view',
  'edit',
  'delete',
];

const MODE_PROPS = {
  row: propTypes.instanceOf(Object).isRequired,
  form: propTypes.instanceOf(Object).isRequired,
};

function JSONView({ jsonString }) {
  try {
    const obj = JSON.parse(jsonString);
    const keys = Object.keys(obj);
    const sections = keys.map((key) => {
      const section = obj[key];
      const questions = Object.keys(section.values)
        .map((question) => (
          <TableRow key={question}>
            <TableCell>
              {question}
            </TableCell>
            <TableCell>
              {section.values[question]}
            </TableCell>
          </TableRow>
        ));
      return (
        <React.Fragment key={key}>
          <TableRow>
            <TableCell>{section.label}</TableCell>
          </TableRow>
          {questions}
          <br />
        </React.Fragment>
      );
    });
    return <Table>{sections}</Table>;
  } catch {
    return jsonString;
  }
}

JSONView.propTypes = {
  jsonString: propTypes.string.isRequired,
};

function View({ row, form }) {
  const keys = Object.keys(row)
    .filter((key) => form[key])
    .filter((key) => !form[key].locked);
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {keys.map((column) => (
            <TableRow key={column}>
              <TableCell>
                {form[column].label || column}
              </TableCell>
              <TableCell>
                {form[column].inputType === 'JSON'
                  ? <JSONView jsonString={row[column]} />
                  : getCell(form, column, row[column])}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
View.propTypes = MODE_PROPS;

function generateInitialAnswers(row) {
  const answers = {};
  Object.keys(row).forEach((key) => {
    answers[key] = row[key];
  });
  return answers;
}

function filterAnswers(answers, row) {
  const postFilter = { ...answers };
  Object.keys(answers).forEach((key) => {
    if (answers[key] === undefined) delete postFilter[key];
    const alwaysInclude = ['id', 'uuid', 'unternehmen_id'];
    if (alwaysInclude.includes(key)) return;
    if (answers[key] === row[key]) delete postFilter[key];
  });
  return postFilter;
}

function getFileFields(answers, row, form) {
  const fields = Object.keys(row);
  const fileFields = fields.filter((fieldName) => (
    form[fieldName]
    && form[fieldName].inputType === 'file'
  ));
  const filteredAnswers = filterAnswers(answers, row);
  const alteredFileFields = fileFields.filter((fieldName) => (
    filteredAnswers[fieldName]
  ));
  return alteredFileFields;
}

function Edit({
  userKey,
  row,
  setRow,
  form,
}) {
  const [state, setState] = useState(STATES.edit);
  const [answers, setAnswers] = useState(
    generateInitialAnswers(row),
  );
  const { table, id } = useParams();

  const handleSubmit = async () => {
    try {
      await editRow(
        table,
        userKey,
        filterAnswers(answers, row),
        getFileFields(answers, row, form),
      );
      const newRow = await getRow(table, userKey, id);
      setRow(newRow);
      setState(STATES.complete);
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  switch (state) {
    case STATES.pending:
      handleSubmit();
      return <Loading />;
    case STATES.edit:
      return (
        <form>
          {`${getFileFields(answers, row, form)}`}
          <div>
            {Object.keys(row).map((column) => {
              if (!form[column]) return null;
              if (form[column].locked) return null;
              return (
                <Input
                  id={column}
                  key={column}
                  label={form[column].label}
                  type={form[column].inputType || 'text'}
                  choices={form[column].choices}
                  value={answers[column]}
                  readOnly={form[column].readOnly}
                  onChange={({ target }) => {
                    answers[column] = target.value;
                    setAnswers({ ...answers });
                  }}
                  accept={form[column].accept}
                />
              );
            })}
          </div>
          <Button
            variant="contained"
            onClick={() => { setState(STATES.pending); }}
          >
            Speichern
          </Button>
        </form>
      );
    case STATES.error: return 'Something went wrong';
    default: return <Navigate to={`/${table}/view/${id}`} />;
  }
}
Edit.propTypes = {
  userKey: propTypes.string.isRequired,
  setRow: propTypes.func.isRequired,
  ...MODE_PROPS,
};

function Delete({ userKey, row }) {
  const [state, setState] = useState(STATES.confirm);
  const { table, id } = useParams();

  const rowName = row.name
    || row.bezeichnung
    || row.id
    || row.uuid;

  switch (state) {
    case STATES.confirm:
      return (
        <div style={{ margin: '20px', textAlign: 'center' }}>
          <Typography>
            {`Sind Sie sicher, das Sie "${rowName}" löschen möchten?`}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              margin: '10px',
            }}
          >
            <Button
              onClick={() => { setState(STATES.cancelled); }}
            >
              Abbrechen
            </Button>
            <Button
              variant="outlined"
              onClick={() => { setState(STATES.pending); }}
            >
              Weiter
            </Button>
          </div>
        </div>
      );
    case STATES.pending:
      deleteRow(table, userKey, id)
        .then(() => { setState(STATES.complete); })
        .catch(() => { setState(STATES.error); });
      return <Loading />;
    case STATES.complete:
      return (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          Erfolgreich gelöscht.
          <br />
          <br />
          <Link to={`/${table}`}>
            <Button>Okay</Button>
          </Link>
        </div>
      );
    case STATES.error: return 'Something went wrong';
    default: return <Navigate to={`/${table}/view/${id}`} />;
  }
}
Delete.propTypes = {
  userKey: propTypes.string.isRequired,
  row: propTypes.instanceOf(Object).isRequired,
};

function RowDetails() {
  const [loading, setLoading] = useState(true);

  const params = useParams();
  const action = MODES.includes(params.action) ? params.action : 'view';
  const id = params.id || 1;
  const { table } = params;

  const loc = useLocation();
  const [details, setDetails] = useState(undefined);
  const [form, setForm] = useState(undefined);

  const { schluessel: key } = useContext(BenutzerContext);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!details) return;
    if (!form) return;
    setLoading(false);
  }, [details, form]);

  useEffect(() => {
    if (!loc.state
      || !loc.state.form) {
      setLoading(true);
      getForm(table, key)
        .then((tableForm) => { setForm(tableForm); })
        .catch(({ message }) => { setErrorMessage(message); });
      return;
    }
    setForm(loc.state.form);
  }, [table]);

  useEffect(() => {
    if (!loc.state
      || !loc.state.row) {
      setLoading(true);
      getRow(table, key, id)
        .then((row) => { setDetails(row); })
        .catch(({ message }) => { setErrorMessage(message); });
      return;
    }
    setDetails(loc.state.row);
  }, [table]);

  if (errorMessage) return errorMessage;
  if (loading) return <ContentBox><Loading /></ContentBox>;
  return (
    <ContentBox style={{ width: '100%' }}>
      <Link to={`/${table}`}>
        <Button>
          <ArrowLeftIcon />
          {`Übersicht ${table}`}
        </Button>
      </Link>
      {action === 'view' && <View row={details} form={form} />}
      {action === 'edit' && (
        <Edit
          userKey={key}
          row={details}
          setRow={setDetails}
          form={form}
        />
      )}
      {action === 'delete' && <Delete userKey={key} row={details} />}
      <RowButtons row={details} parallel />
    </ContentBox>
  );
}

export default RowDetails;
