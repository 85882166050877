/* eslint import/no-extraneous-dependencies: warn */

import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Outlet,
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  createTheme,
  ThemeProvider,
  alpha,
  getContrastRatio,
} from '@mui/material/styles';
import { deDE } from '@mui/material/locale';

import './App.css';
import BenutzerContext, { BenutzerContextAnbieter } from './BenutzerContext';
import {
  colors,
  paletteColors,
  primaryColorCode,
} from './constants';

import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import SignIn from './components/SignIn/SignIn';
import SignOut from './components/SignOut/SignOut';
import Reset from './components/Reset/Reset';
import TableView from './components/Table/TableView';
import RowDetails from './components/RowDetails';
import AddRow from './components/AddRow';
import Dashboard from './components/Dashboard';

const createPalette = () => {
  const palette = {};

  palette.primary = {
    main: primaryColorCode,
    light: alpha(primaryColorCode, 0.5),
    dark: alpha(primaryColorCode, 0.9),
    contrastText: '#fff',
  };
  palette.white = {
    main: '#fff',
    contrastText: primaryColorCode,
  };

  paletteColors.forEach((colorName) => {
    const colorCode = colors[colorName];
    const main = alpha(colorCode, 0.7);
    const light = alpha(colorCode, 0.5);
    const dark = alpha(colorCode, 0.9);
    const contrastText = getContrastRatio(main, '#fff') > 4.5
      ? '#fff'
      : '#111';
    palette[colorName] = {
      main,
      light,
      dark,
      contrastText,
    };
  });
  return palette;
};

const theme = createTheme(
  {
    palette: createPalette(),
    typography: {
      h2: {
        fontSize: '2rem',
      },
    },
    components: {
      MuiTablePagination: {
        styleOverrides: {
          root: {
            textAlign: 'left',
            position: 'relative',
          },
          toolbar: {
            position: 'sticky',
            left: 0,
            width: 'fit-content',
          },
          selectRoot: {
            paddingRight: '10px',
          },
        },
      },
    },
  },
  deDE,
);

function Root() {
  const { schluessel: key } = useContext(BenutzerContext);
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const navClass = sidebarOpen ? 'open' : 'closed';

  useEffect(() => {
    if (!key) navigate('/signin');
  }, [key]);
  return (
    <div className="content-wrapper">
      <nav className={navClass}>
        <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />
      </nav>
      <div className="block-content" />
      <div className="non-nav-content">
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="body-wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BenutzerContextAnbieter>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Root />}>
              <Route path="/:table/:action/:id" element={<RowDetails />} />
              <Route path="/:table/add" element={<AddRow />} />
              <Route path="/:table" element={<TableView />} />
              <Route path="/" element={<Dashboard />} />
            </Route>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signout" element={<SignOut />} />
            <Route
              path="/reset/:code"
              element={<Reset />}
              loader={({ params }) => params.code}
            />
            <Route path="/reset" element={<Reset />} />
            <Route path="*" element={<h2>404</h2>} />
          </Routes>
        </BrowserRouter>
      </BenutzerContextAnbieter>
    </ThemeProvider>
  );
}

export default App;
