import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import ROUTES from './routes';

const BenutzerContext = createContext();

export function BenutzerContextAnbieter({ children }) {
  const [schluessel, setSchluesselState] = useState(
    localStorage.getItem('schluessel'),
  );
  const [storeKey, setStoreKey] = useState(
    localStorage.getItem('storeKey') === 'true',
  );
  const [permissions, setPermissions] = useState(null);
  const [userName, setUserName] = useState(
    localStorage.getItem('userName'),
  );
  const [companyName, setCompanyName] = useState(
    localStorage.getItem('companyName'),
  );

  const version = 1;

  const setSchluessel = (key, store = undefined) => {
    setSchluesselState(key);
    if (store !== undefined) {
      setStoreKey(store);
      localStorage.setItem('storeKey', store);
    }
    if (store) {
      localStorage.setItem('schluessel', key);
    } else {
      localStorage.setItem('schluessel', '');
    }
  };

  useEffect(() => {
    document.title = `${companyName} | Garrels Akustik - App`;
  }, [companyName]);

  useEffect(() => {
    if (!schluessel) { setPermissions(null); return; }
    const baseURL = process.env.REACT_APP_BACKEND_URL;
    const endpoint = ROUTES.permissions;
    const url = `${baseURL}${endpoint}`;
    axios.get(url, { params: { key: schluessel } })
      .then(({ data }) => { setPermissions(data); })
      .catch(() => { setPermissions({}); });
  }, [schluessel]);

  const value = useMemo(() => ({
    schluessel,
    setSchluessel,
    permissions,
    storeKey,
    userName,
    setUserName,
    setCompanyName,
    version,
  }), [schluessel, permissions]);

  return (
    <BenutzerContext.Provider value={value}>
      {children}
    </BenutzerContext.Provider>
  );
}

export default BenutzerContext;

BenutzerContextAnbieter.propTypes = {
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
};

BenutzerContextAnbieter.defaultProps = {
  children: null,
};
