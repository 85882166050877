import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BenutzerContext from '../../BenutzerContext';

import ContentBox from '../Common/ContentBox';

function SignOut() {
  const navigate = useNavigate();
  const {
    setSchluessel,
    setUserName,
  } = useContext(BenutzerContext);
  useEffect(() => {
    localStorage.setItem('userName', '');
    setUserName('');

    localStorage.setItem('schluessel', '');
    setSchluessel('');

    navigate('/signin');
  }, []);

  return (
    <ContentBox>
      Signing out...
    </ContentBox>
  );
}

export default SignOut;
