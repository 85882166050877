import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import BenutzerContext from '../../BenutzerContext';
import Button from '../Common/Button';

function Header({
  sidebarOpen,
  setSidebarOpen,
}) {
  const { userName } = useContext(BenutzerContext);
  return (
    <header>
      <div>
        <IconButton
          onClick={() => { setSidebarOpen(!sidebarOpen); }}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <div className="user-info">
        <Typography sx={{ marginRight: '10px' }}>
          {userName}
        </Typography>
        <Link to="/signout">
          <Button variant="outlined">Ausloggen</Button>
        </Link>
      </div>
    </header>
  );
}
Header.propTypes = {
  sidebarOpen: propTypes.bool.isRequired,
  setSidebarOpen: propTypes.func.isRequired,
};

export default Header;
