import React, {
  useState,
  useContext,
  useEffect,
} from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import propTypes from 'prop-types';
// import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
// import Drawer from '@mui/material/Drawer';

import categories, { tabellen } from '../../constants';
import BenutzerContext from '../../BenutzerContext';

import GarrelsLogo from './garrels-aurich-logo.svg';

function getStyle(tableName, currentTable) {
  if (tableName !== currentTable) {
    return {
      backgroundColor: 'primary.contrastText',
      color: 'primary.main',
    };
  }
  return {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
  };
}

function CategoryLinks({ name, onNav, permissions }) {
  const { table: currentTable } = useParams();

  const [open, setOpen] = useState(true);
  const tables = categories[name]
    .filter((table) => typeof table === 'string')
    .filter((table) => {
      const tableKey = table.toLowerCase();
      const tablePermissions = permissions[tableKey];
      if (!tablePermissions) return false;
      return tablePermissions.includes('get');
    });
  if (tables.length === 0) return null;
  return (
    <List>
      <ListItemButton onClick={() => { setOpen(!open); }}>
        <ListItemText>{name}</ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open}>
        <List>
          {tables.map((table) => (
            <Link
              key={table}
              to={table}
              onClick={onNav}
            >
              <ListItem
                disablePadding
                sx={getStyle(table, currentTable)}
              >
                <ListItemButton key={table}>
                  <ListItemIcon sx={{ color: 'inherit' }}>
                    {tabellen[table].icon}
                  </ListItemIcon>
                  <ListItemText>
                    {table}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Collapse>
    </List>
  );
}

CategoryLinks.propTypes = {
  name: propTypes.string.isRequired,
  onNav: propTypes.func.isRequired,
  permissions: propTypes.instanceOf(Object).isRequired,
};

function Sidebar({ setOpen }) {
  const categoryNames = Object.keys(categories);
  const { permissions, version } = useContext(BenutzerContext);
  const navigate = useNavigate();
  const { table: currentTable } = useParams();

  useEffect(() => {
    if (!permissions) return;
    if (Object.keys(permissions).length) return;
    navigate('/signout');
  }, [permissions]);

  const onNav = () => {
    if (window.innerWidth > 1007) return;
    setOpen(false);
  };

  if (!permissions) return null;
  return (
    <List>
      <img className="logo" src={GarrelsLogo} alt="logo" />
      <Link to="/" onClick={onNav}>
        <ListItem
          sx={getStyle(undefined, currentTable)}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon sx={{ color: 'inherit' }}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItemButton>
        </ListItem>
      </Link>
      {categoryNames.map((name) => (
        <CategoryLinks
          key={name}
          name={name}
          onNav={onNav}
          permissions={permissions}
        />
      ))}
      <div>
        <p>{version}</p>
        <a href="https://groenewold-it.solutions/">
          Support:
          <br />
          Groenewold IT Solutions GmbH
        </a>
      </div>
    </List>
  );
}

Sidebar.propTypes = { setOpen: propTypes.func.isRequired };

export default Sidebar;
