import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import ROUTES from '../../routes';
import { tabellen } from '../../constants';
import BenutzerContext from '../../BenutzerContext';

import { Button, ContentBox, Loading } from '../Common';
import Table from './Table';

import {
  getForm,
} from '../../http';

const VIEWS = {
  add: 'add',
  view: 'view',
  edit: 'edit',
  delete: 'delete',
  loading: 'loading',
  message: 'message',
  table: 'table',
};

function Title({ tableName }) {
  const tableKey = tableName.includes('Auswertungen')
    ? 'Auswertungen'
    : tableName;
  return (
    <Typography
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {tabellen[tableKey].icon}
      <span>{tableName}</span>
    </Typography>
  );
}
Title.propTypes = { tableName: propTypes.string.isRequired };

function TableView() {
  const { table: tableName } = useParams();

  const baseURL = process.env.REACT_APP_BACKEND_URL;
  const url = `${baseURL}${ROUTES[tableName.toLowerCase()]}`;
  const navigate = useNavigate();

  const {
    schluessel: key,
    permissions,
  } = useContext(BenutzerContext);
  const [view, setView] = useState(VIEWS.loading);
  const [message] = useState('');
  const [rowForm, setRowForm] = useState({});
  const defaults = {};
  Object.keys(rowForm).forEach((fieldKey) => {
    if (rowForm[fieldKey].default) {
      defaults[fieldKey] = rowForm[fieldKey].default;
    }
  });

  const hasPermission = (action) => {
    if (!permissions) return false;
    if (!permissions[tableName.toLowerCase()]) return false;
    return permissions[tableName.toLowerCase()].includes(action);
  };
  const errorHandler = (error) => {
    const statusCode = error.response.status;
    console.error(statusCode);
    if (statusCode === 401) navigate('/signout');
  };
  const getRows = () => axios.get(url, { params: { key } });
  const getRowForm = () => {
    getForm(tableName, key)
      .then((data) => {
        setRowForm(data);
        setView(VIEWS.table);
      })
      .catch(errorHandler);
  };
  const getAuswertungen = async (levelsToInclude) => {
    const { data: rows } = await getRows();
    const rowsToReturn = rows.filter((row) => {
      const { auswertung_status: level } = row;
      console.log(level);
      return levelsToInclude.includes(level);
    });
    return { data: rowsToReturn };
  };
  const getUnfinishedAuswertungen = () => getAuswertungen([
    'FORMULAR AUSGEFÜLLT',
    'EINLADUNG VERSENDET',
  ]);
  const getFinishedAuswertungen = () => getAuswertungen([
    'FERTIG',
  ]);

  useEffect(() => {
    setView(VIEWS.loading);
    if (!key) return;
    getRowForm();
  }, [key, tableName]);

  switch (view) {
    case (VIEWS.loading):
      return (<ContentBox><Loading /></ContentBox>);
    case (VIEWS.message):
      return (
        <ContentBox>
          {message}
          <Button onClick={() => { setView(VIEWS.table); }}>Okay</Button>
        </ContentBox>
      );
    case (VIEWS.table):
    default:
      if (tableName === 'Auswertungen') {
        return (
          <>
            <Table
              title={<Title tableName={`${tableName} | Offen`} />}
              form={rowForm}
              getRows={getUnfinishedAuswertungen}
              addRow={null}
            />
            <Table
              title={<Title tableName={`${tableName} | Erledigt`} />}
              form={rowForm}
              getRows={getFinishedAuswertungen}
              addRow={null}
            />
          </>
        );
      }
      return (
        <Table
          title={<Title tableName={tableName} />}
          key={tableName}
          form={rowForm}
          getRows={getRows}
          addRow={hasPermission('post')
            ? () => { setView(VIEWS.add); }
            : null}
        />
      );
  }
}

export default TableView;
