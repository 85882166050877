import Button from '@mui/material/Button';

export default Button;
/*
import propTypes from 'prop-types';

function Button({
  ariaLabel,
  className,
  children,
  onClick,
  isSubmit,
}) {
  return (
    <button
      aria-label={ariaLabel}
      className={className}
      type={(isSubmit ? 'submit' : 'button')}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;

Button.propTypes = {
  ariaLabel: propTypes.string,
  className: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
  onClick: propTypes.func,
  isSubmit: propTypes.bool,
};

Button.defaultProps = {
  ariaLabel: undefined,
  className: undefined,
  children: null,
  onClick: () => {},
  isSubmit: false,
};
*/
