import React, { useState } from 'react';
import { Buffer } from 'buffer';
import { Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { paletteColors } from '../../constants';

import { Button, Dialog } from '../Common';

const TABLE_MAP = {
  unternehmen_id: 'Unternehmen',
  parentID: 'Unternehmen',
  hersteller_id: 'Hersteller',
  mitarbeiter_id: 'Mitarbeiter',
  kunde_id: 'Kunden',
  artikel_id: 'Artikel',
  artikeloption_id: 'Artikeloptionen',
  otoplastik_id: 'Otoplastiken',
  versicherer_id: 'Krankenkassen',
};

function renderColor(key, value) {
  if (key === 'unternehmensfarbcode') {
    document
      .documentElement
      .style
      .setProperty('--primary-color', value);
  }
  return (
    <div
      style={{
        backgroundColor: value,
        height: '25px',
        width: '25px',
        borderRadius: '50%',
      }}
    />
  );
}

function renderImage(key, outerObject, encoding) {
  const innerBuffer = Buffer.from(outerObject.data.data, encoding);
  const base64image = innerBuffer.toString('base64');
  const { mimetype } = outerObject;
  return (
    <img
      className={`image-${key}`}
      src={`data:${mimetype};base64,${base64image}`}
      alt=""
    />
  );
}

function generateTextURL(outerObject, encoding) {
  const innerBuffer = Buffer.from(outerObject.data.data, encoding);
  const fileString = innerBuffer.toString('utf8');
  const type = outerObject.mimetype;
  const name = `${outerObject.name}`;
  const file = new Blob([fileString], { type });
  return (
    <a
      href={window.URL.createObjectURL(file)}
      download={name}
    >
      {name}
    </a>
  );
}

const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];
function renderDate(value) {
  const jsDate = new Date(value);

  const date = jsDate.getDate();
  const month = months[jsDate.getMonth()];
  const year = jsDate.getFullYear();

  const hours = jsDate.getHours();
  const minutes = `${jsDate.getMinutes()}`.padStart(2, '0');

  const dateTime = [`${date} ${month} ${year}`, `${hours}:${minutes} Uhr`];
  return dateTime;
}

function renderFile(key, value) {
  try {
    if (typeof value === 'string') return value;
    const { data, type, name } = value;
    if (type !== 'Buffer') return name;
    if (!data) return null;
    const encoding = 'binary';
    const outerBuffer = Buffer.from(data, encoding);
    const outerBufferString = outerBuffer.toString('utf8');
    if (!outerBufferString) return null;
    const outerObject = JSON.parse(outerBufferString);
    const { mimetype } = outerObject;
    if (mimetype.includes('image')) {
      return renderImage(key, outerObject, encoding);
    }
    if (mimetype.includes('text')) {
      return generateTextURL(outerObject, encoding);
    }
    return outerObject.name;
  } catch {
    return '';
  }
}

function renderTextArea(value) {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => { setIsOpen(true); };
  const close = () => { setIsOpen(false); };
  return (
    <>
      <Button onClick={open}>Ansehen</Button>
      <Dialog open={isOpen}>
        <div className="heading">
          <Typography variant="h5">
            Notizen
          </Typography>
          <Button onClick={close}><CloseIcon /></Button>
        </div>
        <br />
        <br />
        {value}
      </Dialog>
    </>
  );
}

const renderStatus = (text, color) => {
  const label = typeof text === 'string' && text
    ? text
    : null;
  const colorKey = paletteColors.includes(color)
    ? color
    : 'primary';
  if (label === null) return null;
  return <Chip label={label} color={colorKey} />;
};

function renderArray(key, value, form) {
  const { choices } = form[key];
  const labels = value.map((id) => choices[id]);
  return labels.join(', ');
}

const getText = (form, key, value, dependancyValue) => {
  if (key === 'stand') {
    return renderStatus(
      form.stand.choices,
      value,
    );
  }
  if (key === 'auswertung_status') return renderStatus(value);
  if (key === 'kunde_link') return 'Link zum Kundenformular';
  if (key === 'mitarbeiter_hash') return 'Link zu den Antworten';
  if (key === 'umfrage_link') return 'Link zur Umfrage';
  if (value === undefined || value === null) return '';
  const type = form[key] && form[key].inputType;
  if (form[key] && form[key].unit) {
    switch (form[key].unit) {
      case 'Percent':
        return value.toLocaleString(
          'de-DE',
          { minimumSignificantDigits: 2 },
        ).concat('%');
      case 'Euro':
        return value.toLocaleString(
          'de-DE',
          { style: 'currency', currency: 'EUR' },
        );
      default:
    }
  }
  if (type === 'select' && form[key].dependsOn) {
    const { choices } = form[key];
    return choices[dependancyValue][value];
  }
  switch (type) {
    case 'array': return renderArray(key, value, form);
    case 'color': return renderColor(key, value);
    case 'datetime-local': return renderDate(value).join(', ');
    case 'date': return renderDate(value)[0];
    case 'select': return form[key].choices[value];
    case 'file': return renderFile(key, value);
    case 'textarea': return renderTextArea(value);
    default: return `${value}`;
  }
};

const getLink = (key, value) => {
  if (typeof value === 'string'
    && value.slice(0, 6).includes('http')) {
    return value;
  }
  const tableName = TABLE_MAP[key];
  if (!tableName) return '';
  return `/${tableName}/view/${value}`;
};
const getCell = (form, key, value, dependancyValue) => {
  const text = getText(form, key, value, dependancyValue);
  const link = getLink(dependancyValue || key, value);
  if (link) {
    if (link.slice(0, 6).includes('http')) {
      return (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </a>
      );
    }
    return <Link to={link} state={{}}>{text}</Link>;
  }
  return text;
};

export { getText, renderStatus };
export default getCell;
