import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { getForm, addRow } from '../../http';
import BenutzerContext from '../../BenutzerContext';
import {
  Button,
  Dialog,
  Loading,
  Input,
} from '../Common';

const modes = {
  hidden: -1,
  closed: 0,
  open: 1,
  loading: 2,
  error: 3,
};

function setInitialValues(form) {
  const values = {};
  Object.keys(form).forEach((key) => {
    const question = form[key];
    values[key] = question.default;
  });
  return values;
}

function Fields({
  form,
  values,
  setValues,
  mode,
  close,
  submit,
}) {
  const isDisabled = () => {
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (!values[key] && form[key].required) return true;
    }
    return false;
  };

  if (mode === modes.closed) return null;
  if (mode === modes.loading) {
    return (
      <Dialog open>
        <Loading />
      </Dialog>
    );
  }
  if (mode === modes.error) {
    return (
      <Dialog open>
        Etwas ist schiefgelaufen.
      </Dialog>
    );
  }
  return (
    <Dialog open={mode !== modes.closed}>
      <div className="heading">
        <Typography variant="h5">Neuen Kunden erstellen</Typography>
        <Button onClick={close}><CloseIcon /></Button>
      </div>
      <br />
      <form>
        {Object.keys(form).map((key) => {
          const {
            label,
            choices,
            inputType,
            required,
          } = form[key];
          if (!required) return null;
          return (
            <Input
              key={key}
              id={`kunde-${key}`}
              label={label}
              choices={choices}
              type={inputType}
              value={values[key]}
              onChange={(value) => {
                setValues({
                  ...values,
                  [key]: value,
                });
              }}
            />
          );
        })}
        <br />
        <Button
          variant="contained"
          onClick={submit}
          disabled={isDisabled()}
        >
          Erstellen
        </Button>
      </form>
    </Dialog>
  );
}
Fields.propTypes = {
  form: propTypes.instanceOf(Object).isRequired,
  values: propTypes.instanceOf(Object).isRequired,
  setValues: propTypes.func.isRequired,
  close: propTypes.func.isRequired,
  submit: propTypes.func.isRequired,
  mode: propTypes.number.isRequired,
};

function NewKundeForm({ table, selectNewKunde }) {
  if (table !== 'Einladungen') return null;
  const [mode, setMode] = useState(modes.closed);
  const [form, setForm] = useState([]);
  const [values, setValues] = useState({});
  const { schluessel } = useContext(BenutzerContext);

  const close = () => {
    setMode(modes.closed);
    setValues(form);
  };
  const submit = () => {
    setMode(modes.loading);
    addRow('kunden', schluessel, values, false)
      .then(({ insertId }) => {
        const { name, vorname } = values;
        const fullName = `${vorname} ${name}`;
        setMode(modes.closed);
        setValues(setInitialValues(form));
        selectNewKunde(insertId, fullName);
      })
      .catch((error) => {
        console.error(error);
        setMode(modes.error);
      });
  };

  useEffect(() => {
    getForm('kunden', schluessel)
      .then((formObject) => {
        setForm(formObject);
        setValues(setInitialValues(formObject));
      })
      .catch((error) => { console.error(error); });
  }, []);

  return (
    <>
      <Fields
        form={form}
        values={values}
        setValues={setValues}
        close={close}
        submit={submit}
        mode={mode}
      />
      <br />
      <Button
        onClick={() => { setMode(modes.open); }}
        variant={mode === modes.closed
          ? 'contained'
          : 'outlined'}
      >
        Neuen Kunden erstellen
      </Button>
      <br />
    </>
  );
}
NewKundeForm.propTypes = {
  table: propTypes.string.isRequired,
  selectNewKunde: propTypes.func.isRequired,
};

export default NewKundeForm;
