import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import TableSortLabel from '@mui/material/TableSortLabel';
import RuleIcon from '@mui/icons-material/Rule';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonGroup from '@mui/material/ButtonGroup';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import BenutzerContext from '../../BenutzerContext';
import {
  editRow,
  deleteRow,
} from '../../http';
import { tabellen, paletteColors } from '../../constants';

import {
  Button,
  Dialog,
  Input,
  Loading,
} from '../Common';
import AddRowButton from '../AddRow/AddRowButton';
import CSVDownloadButton from './CSVDownloadButton';
import getCell, { getText, renderStatus } from './getCell';
import FilterPanel from './FilterPanel';

const getStyle = (column) => {
  const shared = {};
  if (column !== 'buttons') return shared;
  return {
    position: 'sticky',
    right: 0,
    backgroundColor: '#f3f8fa',
    outline: '1px solid rgba(224, 224, 224, 1)',
  };
};

function Header({
  title,
  form,
  dataGridColumns,
  sortBy,
  setSortBy,
  page,
  rowsPerPage,
  tableLength,
  changePage,
  changeRowsPerPage,
  rowsPerPageOptions,
}) {
  const { table } = useParams();
  const changeSort = (column) => {
    if (column === 'buttons') return;
    const newSortBy = {};
    newSortBy.ascending = sortBy.id === column
      ? !sortBy.ascending
      : true;
    newSortBy.id = column;
    setSortBy(newSortBy);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
        }}
      >
        <TableCell
          colSpan={dataGridColumns.length}
        >
          <div className="table-title-outer">
            <div className="table-title-inner">
              {title}
            </div>
            <div className="table-add-button-wrapper">
              <AddRowButton table={table} form={form} />
            </div>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TablePagination
          page={page}
          rowsPerPage={rowsPerPage}
          count={tableLength}
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          labelRowsPerPage="Einträge pro Seite:"
        />
      </TableRow>
      <TableRow>
        {dataGridColumns.map((col) => (
          <TableCell key={col.field} sx={getStyle(col.field)}>
            <TableSortLabel
              active={sortBy.id === col.field}
              direction={sortBy.ascending ? 'asc' : 'desc'}
              onClick={() => { changeSort(col.field); }}
            >
              {col.headerName}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

Header.propTypes = {
  title: propTypes.string,
  sortBy: propTypes.instanceOf(Object).isRequired,
  setSortBy: propTypes.func.isRequired,
  dataGridColumns: propTypes.arrayOf(Object),
  page: propTypes.number.isRequired,
  rowsPerPage: propTypes.number.isRequired,
  tableLength: propTypes.number.isRequired,
  changePage: propTypes.func.isRequired,
  changeRowsPerPage: propTypes.func.isRequired,
  rowsPerPageOptions: propTypes.arrayOf(propTypes.number).isRequired,
  form: propTypes.instanceOf(Object).isRequired,
};
Header.defaultProps = {
  title: '',
  dataGridColumns: [],
};

function Body({
  form,
  changes,
  editing,
  dataGridColumns,
  dataGridRows,
  primaryKeyField,
  setFileSizeWarning,
}) {
  const cellValue = (rowIndex, column, row) => {
    const key = column.field;
    // const dependancy = form[key] && form[key].dependsOn;
    // const dependancyValue = dependancy && row[dependancy];

    const canEdit = key !== 'buttons'
      && form[key]
      && !form[key].readOnly
      && !form[key].cannotEdit;

    if (editing !== rowIndex || !canEdit) {
      const rawValue = row[key];
      const value = column.valueGetter
        ? column.valueGetter({ row })
        : rawValue;
      return value;
    }

    const handleChange = (value) => {
      const { inputType, maxBytes } = form[key];
      if (inputType === 'file' && value.size > maxBytes) {
        setFileSizeWarning(maxBytes);
        return;
      }
      const nChanges = changes;
      nChanges[key] = value;
    };
    return (
      <Input
        id={`${column.id}-${rowIndex}`}
        defaultValue={row[key]}
        type={form[key].inputType || 'text'}
        choices={form[key].choices}
        onChange={handleChange}
        accept={form[key].accept}
      />
    );
  };

  return (
    <TableBody>
      {dataGridRows.map((row, rowIndex) => (
        <TableRow
          key={row[primaryKeyField]}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          {dataGridColumns.map((col) => (
            <TableCell
              key={col.field}
              sx={getStyle(col.field)}
              component="th"
              scope="row"
            >
              {cellValue(rowIndex, col, row)}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}
Body.propTypes = {
  form: propTypes.instanceOf(Object).isRequired,
  changes: propTypes.instanceOf(Object).isRequired,
  dataGridColumns: propTypes.arrayOf(Object),
  dataGridRows: propTypes.arrayOf(Object),
  editing: propTypes.number,
  primaryKeyField: propTypes.string,
  setFileSizeWarning: propTypes.func.isRequired,
};
Body.defaultProps = {
  dataGridColumns: [],
  dataGridRows: [],
  editing: undefined,
  primaryKeyField: undefined,
};

function Search({ search, setSearch }) {
  return (
    <div className="search-wrapper">
      <TextField
        label="Suchen"
        value={search}
        onChange={({ target }) => { setSearch(target.value); }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
Search.propTypes = {
  search: propTypes.string.isRequired,
  setSearch: propTypes.func.isRequired,
};

function FieldTogglePanel({
  open,
  setOpen,
  form,
  excludedColumns,
  setExcludedColumns,
}) {
  const classNames = ['filter-panel', (open ? 'open' : 'closed')];
  const columns = Object.keys(form).filter((key) => !form[key].locked);
  const handleResponse = (colID) => {
    if (excludedColumns.includes(colID)) {
      setExcludedColumns(excludedColumns.filter((aCol) => aCol !== colID));
      return;
    }
    const newActiveColumns = excludedColumns.concat([colID]);
    const orderedNewColumns = columns.filter((col) => newActiveColumns.includes(col));
    setExcludedColumns(orderedNewColumns);
  };
  return (
    <div className={classNames.join(' ')}>
      <div className="heading">
        <Typography variant="h5">Sichtbare Felder auswählen</Typography>
        <Button onClick={() => { setOpen(false); }}><CloseIcon /></Button>
      </div>
      <div className="options">
        {columns.map((colID) => (
          <Input
            type="checkbox"
            id={`${colID}-toggle`}
            key={`${colID}-toggle`}
            label={form[colID].label || colID}
            checked={!excludedColumns.includes(colID)}
            onChange={() => { handleResponse(colID); }}
          />
        ))}
      </div>
      <ButtonGroup>
        <Button onClick={() => { setExcludedColumns([]); }}>
          Alle Felder ansehen
        </Button>
        <Button onClick={() => { setExcludedColumns(Object.keys(form)); }}>
          Keine Felder ansehen
        </Button>
      </ButtonGroup>
    </div>
  );
}
FieldTogglePanel.propTypes = {
  open: propTypes.bool.isRequired,
  setOpen: propTypes.func.isRequired,
  form: propTypes.instanceOf(Object).isRequired,
  excludedColumns: propTypes.arrayOf(propTypes.string).isRequired,
  setExcludedColumns: propTypes.func.isRequired,
};
function FieldToggleButton({
  disabled,
  fieldToggleOpen,
  setFieldToggleOpen,
}) {
  return (
    <IconButton
      color={fieldToggleOpen ? 'primary' : 'default'}
      disabled={disabled}
      onClick={() => { setFieldToggleOpen(!fieldToggleOpen); }}
    >
      <RuleIcon />
    </IconButton>
  );
}
FieldToggleButton.propTypes = {
  disabled: propTypes.bool,
  fieldToggleOpen: propTypes.bool.isRequired,
  setFieldToggleOpen: propTypes.func.isRequired,
};
FieldToggleButton.defaultProps = { disabled: undefined };

function FilterButton({
  disabled,
  filterOpen,
  setFilterOpen,
}) {
  return (
    <IconButton
      color={filterOpen ? 'primary' : 'default'}
      disabled={disabled}
      onClick={() => { setFilterOpen(!filterOpen); }}
    >
      <FilterListIcon />
    </IconButton>
  );
}
FilterButton.propTypes = {
  disabled: propTypes.bool.isRequired,
  filterOpen: propTypes.bool.isRequired,
  setFilterOpen: propTypes.func.isRequired,
};

function constructFilterFields(form, data) {
  const keys = Object.keys(form);

  const canFilterOn = (key) => (form[key].filter
    && data[0]
    && Object.keys(data[0]).includes(key)
  );

  const objects = keys
    .filter(canFilterOn)
    .map((key) => {
      const formObject = form[key];
      const choices = formObject.choices || {};
      if (!Object.keys(choices).length) {
        data.forEach((row) => {
          const value = row[key];
          if (!value) return;
          choices[value] = value;
        });
      }
      return {
        id: key,
        name: formObject.label,
        selection: 'default',
        choices: { default: 'Alle', ...choices },
      };
    });
  return objects;
}

function createCSV(form, data) {
  const columnKeys = Object.keys(form)
    .filter((key) => !form[key].locked);
  const columns = columnKeys
    .map((key) => {
      const value = form[key].label || key;
      if (value.includes(',')) return `"${value}"`;
      return value;
    })
    .join(',');
  const rows = data.map((row) => {
    const rowString = columnKeys
      .map((key) => {
        let value = form[key].choices
          ? form[key].choices[row[key]]
          : row[key];

        const isDate = typeof value === 'string' && value.includes('00:00.000Z');
        if (isDate) {
          const jsDate = new Date(value);
          const yyyy = `${jsDate.getFullYear()}`.padStart(4, '0');
          const mm = `${jsDate.getMonth() + 1}`.padStart(2, '0');
          const dd = `${jsDate.getDate()}`.padStart(2, '0');
          value = `${dd}-${mm}-${yyyy}`;
        }

        const hasCommas = (typeof value === 'string'
          && value.includes(','));
        if (hasCommas) return `"${value}"`;
        return value;
      })
      .join(',');
    return rowString;
  });
  const lines = ['\ufeff'.concat(columns)].concat(rows);
  const csv = lines.join('\n');

  const file = new Blob([csv], { type: 'text/csv' });
  return file;
}

function lowerCasify(string) {
  return string
    .toLowerCase()
    .replace(/ß/g, 'ss')
    .replace(/oe|ö/g, 'o')
    .replace(/ae|ä/g, 'a')
    .replace(/ue|ü/g, 'u');
}

function matchesFilter(row, appliedFilters) {
  const keys = Object.keys(appliedFilters);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const stringFilter = String(appliedFilters[key]);
    const stringValue = String(row[key]);
    if (stringFilter !== stringValue) return false;
  }
  return true;
}
function matchesSearch(row, form, search) {
  if (!search) return true;
  const lowerCaseSearch = lowerCasify(search);
  const keys = Object.keys(row);
  const values = keys.map((key) => {
    const symbolicValue = form[key].choices !== undefined;
    const cellValue = symbolicValue
      ? form[key].choices[row[key]]
      : row[key];
    return String(cellValue);
  });
  for (let i = 0; i < values.length; i += 1) {
    const value = lowerCasify(values[i]);
    if (value.includes(lowerCaseSearch)) return true;
  }
  return false;
}

function filterData(form, data, filterFields, search) {
  const appliedFilters = {};
  filterFields.forEach(({ id, selection }) => {
    if (selection === 'default') return;
    appliedFilters[id] = selection;
  });

  return data.filter((row) => {
    if (!matchesFilter(row, appliedFilters)) return false;
    if (!matchesSearch(row, form, search)) return false;
    return true;
  });
}

function filterForm(rawForm) {
  const filtered = {};
  const keys = Object.keys(rawForm);
  keys.forEach((key) => {
    if (rawForm[key].cannotView) return;
    filtered[key] = { ...rawForm[key] };
  });
  return filtered;
}

function sortData(form, data, sortBy) {
  return data.sort((rowA, rowB) => {
    const key = sortBy.id;
    const a = getText(form, key, rowA[key]);
    const b = getText(form, key, rowB[key]);
    const direction = sortBy.ascending ? 1 : -1;
    if (a < b) return -1 * direction;
    if (a > b) return 1 * direction;
    return 0;
  });
}

function RowButtons({
  rowIndex,
  editing,
  setEditing,
  saveEdit,
  cancelEdit,
  setDeleting,
  canEdit,
  canDelete,
}) {
  const beginEdit = () => { setEditing(rowIndex); };
  if (editing !== rowIndex) {
    return (
      <ButtonGroup>
        {canEdit && <IconButton onClick={beginEdit}><EditIcon /></IconButton>}
        {canDelete
          && <IconButton onClick={() => { setDeleting(rowIndex); }}><DeleteIcon /></IconButton>}
      </ButtonGroup>
    );
  }
  return (
    <ButtonGroup>
      <IconButton color="error" onClick={cancelEdit}><ClearIcon /></IconButton>
      <IconButton color="success" onClick={saveEdit}><DoneIcon /></IconButton>
      <IconButton disabled onClick={() => { setDeleting(rowIndex); }}><DeleteIcon /></IconButton>
    </ButtonGroup>
  );
}
RowButtons.propTypes = {
  rowIndex: propTypes.number.isRequired,
  editing: propTypes.number,
  setEditing: propTypes.func.isRequired,
  saveEdit: propTypes.func.isRequired,
  cancelEdit: propTypes.func.isRequired,
  setDeleting: propTypes.func.isRequired,
  canEdit: propTypes.bool,
  canDelete: propTypes.bool,
};
RowButtons.defaultProps = {
  canEdit: false,
  canDelete: false,
  editing: undefined,
};

function FileSizeWarning({ fileSizeWarning, setFileSizeWarning }) {
  if (!fileSizeWarning) return null;

  const power = Math.floor(Math.log(fileSizeWarning) / Math.log(1024));
  const unit = power === 1 ? 'KB' : 'MB';
  const number = fileSizeWarning / (1024 ** power);
  const measure = `${number} ${unit}`;

  return (
    <Dialog open>
      {`Bitte wählen Sie eine Datei aus, die weniger als ${measure} hat.`}
      <div style={{ margin: '20px auto', width: 'fit-content' }}>
        <Button
          variant="outlined"
          onClick={() => { setFileSizeWarning(0); }}
        >
          Okay
        </Button>
      </div>
    </Dialog>
  );
}
FileSizeWarning.propTypes = {
  fileSizeWarning: propTypes.number.isRequired,
  setFileSizeWarning: propTypes.func.isRequired,
};

const getPrimaryKeyField = (form) => {
  try {
    const keys = Object.keys(form);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (form[key].primaryKey) return key;
    }
    return undefined;
  } catch { return undefined; }
};
const excludedColumnsKey = (table) => `${table}-excluded-columns`;
const getExcludedColumns = (table) => {
  const key = excludedColumnsKey(table);
  const saved = localStorage.getItem(key);
  if (!saved) return [];
  const parsed = JSON.parse(saved);
  if (!Array.isArray(parsed)) return [];
  return parsed;
};
const saveExcludedColumns = (table, excludedColumns) => {
  const key = excludedColumnsKey(table);
  const string = JSON.stringify(excludedColumns);
  localStorage.setItem(key, string);
};

function Tabelle({
  title,
  form,
  getRows,
}) {
  const { table } = useParams();

  const [excludedColumns, setExcludedColumns] = useState(
    getExcludedColumns(table),
  );
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [fieldToggleOpen, setFieldToggleOpen] = useState(false);
  const [filterFields, setFilterFields] = useState([]);

  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [25, 50, 100, 250];
  const [rowsPerPage, setRowsPerPage] = useState(
    rowsPerPageOptions[0],
  );
  const changePage = (event, newPage) => {
    setPage(newPage);
  };
  const changeRowsPerPage = ({ target }) => {
    setRowsPerPage(Number(target.value));
    setPage(0);
  };

  const primaryKeyField = getPrimaryKeyField(form);

  const [sortBy, setSortBy] = useState({
    id: null,
    ascending: true,
  });
  const [csv, setCSV] = useState(createCSV(form, data));
  const { schluessel: key, permissions } = useContext(BenutzerContext);

  const [editing, setEditing] = useState();
  const [fileSizeWarning, setFileSizeWarning] = useState(0);
  const [editChanges, setEditChanges] = useState({});
  const [applyingEdit, setApplyingEdit] = useState(false);
  const cancelEdit = () => { setEditing(undefined); setEditChanges({}); };
  const saveEdit = () => {
    setApplyingEdit(true);
    const row = filteredData[editing];
    editChanges[primaryKeyField] = row[primaryKeyField];
    const fileFields = Object.keys(editChanges)
      .filter((editKey) => form[editKey]
        && form[editKey].inputType
        && form[editKey].inputType === 'file');
    editRow(table, key, editChanges, fileFields)
      .then(() => {
        setEditing(undefined);
        const changeKeys = Object.keys(editChanges);
        changeKeys.forEach((changeKey) => { row[changeKey] = editChanges[changeKey]; });
        setEditChanges({});
        setApplyingEdit(false);
        setCSV(createCSV(form, filteredData));
        setFilterFields(
          constructFilterFields(
            form,
            data,
          ),
        );
      })
      .catch(() => {
        setEditing(undefined);
        setEditChanges({});
        setApplyingEdit(false);
      });
  };
  const [deleting, setDeleting] = useState();
  const cancelDelete = () => { setDeleting(undefined); };
  const confirmDelete = () => {
    const row = data[deleting];
    deleteRow(table, key, row[primaryKeyField])
      .then(() => {
        setDeleting(undefined);
        const newData = data.slice(0, deleting).concat(data.slice(deleting + 1));
        setData(newData);
        setFilterFields(
          constructFilterFields(
            form,
            newData,
          ),
        );
      })
      .catch(() => { setDeleting(undefined); });
  };

  const applyFilterFields = (fieldID, value) => {
    filterFields.forEach((field) => {
      if (field.id !== fieldID) return;
      const newField = field;
      newField.selection = value;
    });
    setFilterFields([...filterFields]);
  };
  const resetFilterFields = () => {
    const newFilterFields = filterFields.map((field) => ({ ...field, selection: 'default' }));
    setFilterFields(newFilterFields);
  };

  useEffect(() => {
    const newFilteredData = filterData(form, data, filterFields, search);
    const sortedData = sortData(form, newFilteredData, sortBy);
    setFilteredData(sortedData);
    setCSV(createCSV(form, sortedData));
  }, [data, search, filterFields, sortBy]);

  useEffect(() => {
    saveExcludedColumns(table, excludedColumns);
  }, [excludedColumns]);

  useEffect(() => {
    if (!key) return;
    getRows(table, key)
      .then(({ data: newData }) => {
        setData(newData);
        setFilterFields(
          constructFilterFields(
            form,
            newData,
          ),
        );
      })
      .catch(({ message }) => { console.error(message); });
  }, [key, table]);

  const valueGetter = (fieldName) => {
    if (fieldName === 'buttons') return (params) => params.row.buttons;
    if (fieldName === 'text') {
      return ({ row }) => {
        const { text, farbcode } = row;
        const color = paletteColors.includes(farbcode)
          ? farbcode
          : 'primary';
        return renderStatus(text, color);
      };
    }
    if (fieldName === 'stand') {
      return (({ row }) => {
        const value = row.stand;
        const color = form.stand.colors[value];
        const text = form.stand.choices[value];
        return renderStatus(text, color);
      });
    }
    return (params) => {
      const { row } = params;
      const { dependsOn } = form[fieldName];
      const dependancyValue = dependsOn && row[dependsOn];
      return getCell(form, fieldName, row[fieldName], dependancyValue);
    };
  };
  const dataGridColumns = Object.keys(form)
    .filter((colID) => (form[colID]
      && !form[colID].cannotView
      && !form[colID].locked
      && !excludedColumns.includes(colID)))
    .map((colID) => ({
      field: colID,
      headerName: (form[colID] && form[colID].label) || colID,
      valueGetter: valueGetter(colID),
    })).concat({
      field: 'buttons',
      headerName: (
        <>
          <FieldToggleButton
            fieldToggleOpen={fieldToggleOpen}
            setFieldToggleOpen={setFieldToggleOpen}
          />
          <FilterButton
            disabled={!filterFields.length}
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
          />
        </>
      ),
    });
  const dataGridRows = filteredData.map((row, rowIndex) => {
    const tRow = { ...row };
    if (!row.id && row.uuid) tRow.id = row.uuid;
    if (!row.id && row.unternehmen_id) tRow.id = row.unternehmen_id;
    tRow.buttons = (
      <RowButtons
        rowIndex={rowIndex}
        editing={editing}
        setEditing={setEditing}
        cancelEdit={cancelEdit}
        saveEdit={saveEdit}
        setDeleting={setDeleting}
        canEdit={permissions[table.toLowerCase()].includes('put')}
        canDelete={permissions[table.toLowerCase()].includes('delete')}
      />
    );
    return tRow;
  });

  const declineDies = (gender) => {
    switch (gender) {
      case 'neuter': return 'dieses';
      case 'feminine': return 'diese';
      default: return 'diesen';
    }
  };

  if (!form) return null;
  const filteredForm = filterForm(form);
  return (
    <div className="table-wrapper">
      <FileSizeWarning
        fileSizeWarning={fileSizeWarning}
        setFileSizeWarning={setFileSizeWarning}
      />
      <Dialog open={applyingEdit}><Loading /></Dialog>
      <Dialog open={deleting !== undefined}>
        <div style={{ margin: '20px' }}>
          Sind Sie sicher, das Sie
          {` ${declineDies(tabellen[table].gender)} `}
          {` ${tabellen[table].singular || table} `}
          löschen möchten?
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            color="error"
            onClick={cancelDelete}
          >
            Ablehnen
          </Button>
          <Button
            variant="outlined"
            color="success"
            onClick={confirmDelete}
          >
            Bestätigen
          </Button>
        </div>
      </Dialog>
      <div className="addRowButton-wrapper">
        <Search search={search} setSearch={setSearch} />
      </div>
      <FieldTogglePanel
        open={fieldToggleOpen}
        setOpen={setFieldToggleOpen}
        form={filteredForm}
        excludedColumns={excludedColumns}
        setExcludedColumns={setExcludedColumns}
      />
      <FilterPanel
        open={filterOpen}
        setOpen={setFilterOpen}
        fields={filterFields}
        applyFilter={applyFilterFields}
        resetFilters={resetFilterFields}
      />
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <Header
            title={title}
            form={form}
            sortBy={sortBy}
            setSortBy={setSortBy}
            dataGridColumns={dataGridColumns}
            page={page}
            rowsPerPage={rowsPerPage}
            tableLength={dataGridRows.length}
            rowsPerPageOptions={rowsPerPageOptions}
            changePage={changePage}
            changeRowsPerPage={changeRowsPerPage}
          />
          <Body
            form={filteredForm}
            changes={editChanges}
            dataGridColumns={dataGridColumns}
            dataGridRows={
              dataGridRows.slice(
                (page * rowsPerPage),
                (page * rowsPerPage) + rowsPerPage,
              )
            }
            editing={editing}
            primaryKeyField={primaryKeyField}
            setFileSizeWarning={setFileSizeWarning}
          />
        </Table>
      </TableContainer>
      <div
        style={{
          margin: '10px 0',
          textAlign: 'right',
        }}
      >
        <CSVDownloadButton table={table} CSV={csv} />
      </div>
    </div>
  );
}

export default Tabelle;

Tabelle.propTypes = {
  title: propTypes.string,
  form: propTypes.instanceOf(Object).isRequired,
  getRows: propTypes.func.isRequired,
};
Tabelle.defaultProps = {
  title: '',
};
