import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Button } from '../Common';
import BenutzerContext from '../../BenutzerContext';
import { tabellen } from '../../constants';

function AddRowButton({ table, form }) {
  const { permissions } = useContext(BenutzerContext);
  const tableKey = table.toLowerCase();
  const singular = tabellen[table].singular || table;

  if (!permissions) return null;
  if (permissions[tableKey].includes('post')) {
    return (
      <Link to={`/${table}/add`} state={{ form }}>
        <Button
          color="white"
          variant="outlined"
          startIcon={<AddCircleIcon />}
        >
          {`${singular} erstellen`}
        </Button>
      </Link>
    );
  }
  return null;
}

export default AddRowButton;

AddRowButton.propTypes = {
  table: propTypes.string.isRequired,
  form: propTypes.instanceOf(Object).isRequired,
};
