import React from 'react';
import propTypes from 'prop-types';

import './ContentBox.css';

function ContentBox({ children, style }) {
  return (
    <div className="content-box" style={style}>
      {children}
    </div>
  );
}

export default ContentBox;

ContentBox.propTypes = {
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
  style: propTypes.instanceOf(Object),
};

ContentBox.defaultProps = {
  children: null,
  style: {},
};
