import React, { useState, useMemo } from 'react';
import propTypes from 'prop-types';
import MUISelect from '@mui/material/Select';
import MUITextField from '@mui/material/TextField';
import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import Button from './Button';
import Dialog from './Dialog';

import './Input.css';

function TextArea({
  value,
  onChange,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState(value);
  const open = () => { setIsOpen(true); };
  const close = () => { setIsOpen(false); };
  const submit = () => { onChange(text); close(); };

  return (
    <>
      <Dialog open={isOpen}>
        <div className="heading">
          <Typography variant="h5">
            Notizen bearbeiten
          </Typography>
          <Button onClick={close}><CloseIcon /></Button>
        </div>
        <div style={{ marginTop: '20px' }}>
          <MUITextField
            fullWidth
            multiline
            minRows={5}
            value={text}
            onChange={({ target }) => { setText(target.value); }}
          />
        </div>
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={close} variant="outlined">Abbrechen</Button>
          <Button onClick={submit} variant="contained">Bestätigen</Button>
        </div>
      </Dialog>
      <Button onClick={open}>Notiz Bearbeiten</Button>
    </>
  );
}
TextArea.propTypes = {
  value: propTypes.string,
  onChange: propTypes.func.isRequired,
};
TextArea.defaultProps = { value: '' };

function ArraySelect({
  id,
  value,
  onChange,
  choices,
  label,
}) {
  const options = Object.keys(choices).map((choiceID) => ({
    label: choices[choiceID],
    id: choiceID,
  }));
  const onClick = (event, newValue) => {
    const newEvent = {
      target: {
        value: newValue.map((item) => item.id || item),
      },
    };
    onChange(newEvent);
  };
  const match = ({ id: idA }, idB) => `${idA}` === `${idB}`;
  const getOptionLabel = (valueToLabel) => {
    const valueID = valueToLabel.id || valueToLabel;
    const correspondingOption = options
      .find(({ id: optionID }) => `${optionID}` === `${valueID}`);
    return correspondingOption ? correspondingOption.label : valueID;
  };
  return (
    <Autocomplete
      multiple
      sx={{ maxWidth: '100%' }}
      autoComplete
      includeInputInList
      popupIcon={null}
      id={id}
      value={value}
      options={options}
      isOptionEqualToValue={match}
      onChange={onClick}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <MUITextField
          {...params} // eslint-disable-line
          label={label}
          value={value}
        />
      )}
    />
  );
}

function Select({
  id,
  defaultValue,
  value,
  onChange,
  choices,
  label,
}) {
  if (Object.keys(choices).length > 8) {
    const options = Object.keys(choices).map((choiceID) => ({
      label: choices[choiceID],
      id: choiceID,
    }));
    const convertedValue = useMemo(() => ({
      label: choices[value || defaultValue] || '',
      id: value || defaultValue,
    }), [defaultValue]);
    const onClick = (event) => {
      const optionLabel = event.target.textContent;
      const optionObject = options
        .find((option) => option.label === optionLabel);
      const optionID = optionObject ? optionObject.id : value;
      const newEvent = { target: { value: optionID } };
      onChange(newEvent);
    };
    const match = ({ id: idA }, { id: idB }) => idA === idB;
    return (
      <Autocomplete
        sx={{ maxWidth: '100%' }}
        autoComplete
        includeInputInList
        popupIcon={null}
        id={id}
        defaultValue={convertedValue}
        options={options}
        isOptionEqualToValue={match}
        onChange={onClick}
        renderInput={(params) => (
          <MUITextField
            {...params} // eslint-disable-line
            label={label}
            value={value}
          />
        )}
      />
    );
  }
  const getCurrentValue = () => {
    if (Array.isArray(choices)) {
      const choice = choices.find(({ id: choiceID }) => value === choiceID
        || defaultValue === choiceID);
      return choice || '';
    }
    if (typeof choices === 'object') {
      if (choices[value]) return value;
      if (choices[defaultValue]) return defaultValue;
      return '';
    }
    return '';
  };
  return (
    <FormControl>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <MUISelect
        sx={{ maxWidth: '100%' }}
        labelId={`${id}-label`}
        id={id}
        value={getCurrentValue()}
        onChange={onChange}
        defaultValue={defaultValue}
        label={label}
        autoWidth
      >
        {Object.keys(choices).map((key) => (
          <MenuItem key={key} value={key}>{choices[key]}</MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
}

Select.propTypes = {
  label: propTypes.string,
  id: propTypes.string.isRequired,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]),
  defaultValue: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]),
  onChange: propTypes.func,
  choices: propTypes.instanceOf(Object),
};
ArraySelect.propTypes = Select.propTypes;
Select.defaultProps = {
  label: '',
  value: undefined,
  defaultValue: undefined,
  onChange: () => {},
  choices: {},
};
ArraySelect.defaultProps = Select.defaultProps;

function formatColor(color) {
  if (!color) return undefined;
  if (typeof color !== 'string') return '#000000';
  if (color.includes('rgb(')) {
    const valuesString = color
      .substring(4, color.length - 1)
      .replace(/ /g, '');
    const valuesArray = valuesString
      .split(',')
      .map((str) => Number(str).toString(16).padStart(2, '0'));
    return `#${valuesArray.join('')}`;
  }
  return color;
}

function DateTime({
  onChange,
  required,
  value,
  defaultValue,
  id,
  readOnly,
}) {
  const formatDateTime = () => {
    // "2023-05-17T14:30:00.000Z"
    const preFormat = value || defaultValue;
    if (!preFormat) return [undefined, undefined];
    const [dateString, timeString] = preFormat.split('T');
    if (dateString === 'undefined'
      || timeString === 'undefined') {
      const tempDate = dateString === 'undefined' ? undefined : dateString;
      const tempTime = timeString === 'undefined' ? undefined : timeString;
      return [tempDate, tempTime];
    }
    const jsDate = new Date(preFormat);

    const day = `${jsDate.getDate()}`.padStart(2, '0');
    const month = `${jsDate.getMonth() + 1}`.padStart(2, '0');
    const year = jsDate.getFullYear();
    const date = `${year}-${month}-${day}`;

    const hours = `${jsDate.getHours()}`.padStart(2, '0');
    const minutes = `${jsDate.getMinutes()}`.padStart(2, '0');
    const time = `${hours}:${minutes}`;
    return [date, time];
  };
  const [date, time] = formatDateTime();

  console.log(new Date(`${date}T${time}`));
  const beforeNow = new Date(`${date}T${time}`) < new Date();
  const invalidDate = Boolean(value
    && (!date
      || date === 'undefined'
      || beforeNow));
  const invalidTime = Boolean(value
    && (!time || time === 'undefined' || beforeNow));

  const responseSetter = (part, newValue) => {
    const [prevDate, prevTime] = formatDateTime();
    const joint = (part === 'time')
      ? `${prevDate}T${newValue}`
      : `${newValue}T${prevTime}`;
    onChange({ target: { value: joint } });
  };

  return (
    <>
      <div className="input-wrapper">
        <MUITextField
          id={id}
          type="date"
          defaultValue={date}
          onChange={({ target }) => {
            responseSetter('date', target.value);
          }}
          required={required}
          label="Datum"
          disabled={readOnly}
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={invalidDate}
        />
      </div>
      <div className="input-wrapper">
        <MUITextField
          id={id}
          type="time"
          defaultValue={time}
          onChange={({ target }) => {
            responseSetter('time', target.value);
          }}
          required={required}
          label="Zeit"
          disabled={readOnly}
          fullWidth
          error={invalidTime}
          InputLabelProps={{ shrink: true }}
        />
      </div>
    </>
  );
}
DateTime.propTypes = {
  id: propTypes.string.isRequired,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]),
  defaultValue: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]),
  onChange: propTypes.func,
  required: propTypes.bool,
  readOnly: propTypes.bool,
};
DateTime.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  onChange: () => {},
  required: undefined,
  readOnly: undefined,
};

function formatDate(value) {
  if (!value) return value;

  const jsDate = new Date(value);
  if (!jsDate) return value;

  const YYYY = `${jsDate.getFullYear()}`.padStart(4, '0');
  const MM = `${jsDate.getMonth() + 1}`.padStart(2, '0');
  const DD = `${jsDate.getDate()}`.padStart(2, '0');
  return `${YYYY}-${MM}-${DD}`;
}

function toString(value, type) {
  switch (type) {
    case 'array':
      if (value === '') return [];
      return value;
    case 'number':
      if (!value) return 0;
      return Number(value).toLocaleString('de-DE');
    case 'date': return formatDate(value);
    case 'color': return formatColor(value);
    default: return value;
  }
}

const errorMessages = {
  required: 'Dieses Field ist erforderlich.',
  number: 'Eingabe muss eine Nummer mit Format "1.234,56" sein.',
};

function angliciseNumberString(numberString) {
  const validTypes = ['number', 'string'];
  if (!validTypes.includes(typeof numberString)) return NaN;
  if (Number.isNaN(numberString)) return NaN;
  if (typeof numberString === 'number') return numberString;

  const reformattedString = String(numberString).replaceAll('.', '').replaceAll(',', '.');
  const number = Number(reformattedString);
  return number;
}

function validateInput(value, type) {
  const noError = false;
  switch (type) {
    case 'number':
      if (Number.isNaN(angliciseNumberString(value))) return errorMessages.number;
      return noError;
    default: return noError;
  }
}

function fromString(value, type) {
  switch (type) {
    case 'number': return angliciseNumberString(value);
    default: return value;
  }
}

function Input({
  type,
  label,
  id,
  value,
  defaultValue,
  onChange,
  checked,
  choices,
  required,
  readOnly,
  accept,
  reportError,
}) {
  const [localValue, setLocalValue] = useState(toString(value, type)
    || toString(defaultValue, type)
    || '');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const onLocalValueChange = ({ target }) => {
    const { value: newValue } = target;
    setLocalValue(newValue);
    const invalid = validateInput(newValue, type);
    if (invalid) {
      setError(true);
      reportError(true);
      setHelperText(invalid);
    } else {
      onChange(fromString(newValue, type));
      setHelperText('');
      setError(false);
      reportError(false);
    }
  };
  switch (type) {
    case 'checkbox':
      return (
        <FormControlLabel
          control={(
            <MUICheckbox
              id={id}
              type={type}
              value={localValue}
              onChange={onLocalValueChange}
              checked={checked}
              required={required}
              disabled={readOnly}
            />
          )}
          label={label}
        />
      );
    case 'datetime-local':
      return (
        <DateTime
          value={localValue}
          onChange={onLocalValueChange}
          required={required}
          id={id}
          readOnly={readOnly}
        />
      );
    case 'file':
      return (
        <>
          <label className="file" htmlFor={id}>{label}</label>
          <input
            id={id}
            type={type}
            /* defaultValue={getFileObject(defaultValue)} */
            onChange={({ target }) => {
              const [file] = target.files;
              if (typeof accept === 'string' && !accept.includes(file.type)) return;
              onChange(file);
            }}
            accept={accept}
            checked={checked}
            disabled={readOnly}
            required={required}
          />
        </>
      );
    case 'array':
      return (
        <ArraySelect
          id={id}
          value={localValue}
          checked={checked}
          choices={choices}
          onChange={onLocalValueChange}
          label={label}
          disabled={readOnly}
        />
      );
    case 'select':
      return (
        <Select
          id={id}
          value={localValue}
          checked={checked}
          choices={choices}
          onChange={onLocalValueChange}
          label={label}
          disabled={readOnly}
        />
      );
    default:
      return (
        <MUITextField
          id={id}
          type={type === 'number' ? 'text' : type}
          value={localValue}
          onChange={(e) => {
            if (type === 'date' && e.target.value === '') return;
            onLocalValueChange(e);
          }}
          checked={checked}
          required={required}
          label={label}
          disabled={readOnly}
          fullWidth
          InputLabelProps={{ shrink: type === 'date' ? true : undefined }}
          error={error}
          helperText={helperText}
        />
      );
  }
}

function InputWrapper({
  type,
  label,
  id,
  value,
  defaultValue,
  onChange,
  checked,
  choices,
  required,
  readOnly,
  accept,
  reportError,
}) {
  if (type === 'datetime-local') {
    return (
      <Input
        type={type}
        label={label}
        id={id}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        checked={checked}
        choices={choices}
        required={required}
        readOnly={readOnly}
        accept={accept}
        reportError={reportError}
      />
    );
  }
  if (type === 'textarea') {
    return (
      <TextArea
        value={value || defaultValue}
        onChange={onChange}
      />
    );
  }
  return (
    <div className="input-wrapper">
      <Input
        type={type}
        label={label}
        id={id}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        checked={checked}
        choices={choices}
        required={required}
        readOnly={readOnly}
        accept={accept}
        reportError={reportError}
      />
    </div>
  );
}

export default InputWrapper;

const inputProps = {
  type: propTypes.string.isRequired,
  label: propTypes.string,
  id: propTypes.string.isRequired,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]),
  defaultValue: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
  ]),
  onChange: propTypes.func,
  checked: propTypes.bool,
  choices: propTypes.instanceOf(Object),
  required: propTypes.bool,
  readOnly: propTypes.bool,
  accept: propTypes.string,
  reportError: propTypes.func,
};
const inputDefaultProps = {
  label: '',
  value: undefined,
  defaultValue: undefined,
  onChange: () => {},
  checked: undefined,
  choices: undefined,
  required: undefined,
  readOnly: undefined,
  accept: undefined,
  reportError: () => {},
};

Input.propTypes = inputProps;
Input.defaultProps = inputDefaultProps;
InputWrapper.propTypes = inputProps;
InputWrapper.defaultProps = inputDefaultProps;
