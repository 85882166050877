import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';

import BenutzerContext from '../../BenutzerContext';

function RowButtons({ row }) {
  const id = row.id || row.uuid || row.unternehmen_id;
  const { table } = useParams();
  const { permissions } = useContext(BenutzerContext);

  const link = (mode) => `/${table}/${mode}/${id}`;
  const icons = {
    view: <VisibilityIcon />,
    edit: <EditIcon />,
    delete: <DeleteIcon />,
  };
  const modes = [];
  const tablePermissions = permissions[table.toLowerCase()];
  if (tablePermissions.includes('get')) modes.push('view');
  if (tablePermissions.includes('put')) modes.push('edit');
  if (tablePermissions.includes('delete')) modes.push('delete');

  return (
    <div>
      <ButtonGroup variant="text" size="large">
        {modes.map((mode) => (
          <Link key={mode} to={link(mode)} state={{ row }}>
            <IconButton ariaLabel={`${mode} row`}>
              {icons[mode]}
            </IconButton>
          </Link>
        ))}
      </ButtonGroup>
    </div>
  );
}

export default RowButtons;

RowButtons.propTypes = {
  row: propTypes.shape({
    id: propTypes.number,
    uuid: propTypes.number,
    unternehmen_id: propTypes.number,
  }).isRequired,
};
