const translations = {
  blue: 'Blau',
  indigo: 'Indigo',
  purple: 'Violett',
  pink: 'Rosa',
  red: 'Rot',
  orange: 'Orange',
  yellow: 'Gelb',
  green: 'Grün',
  teal: 'Blaugrün',
  cyan: 'Türkis',
};
const translate = (word) => translations[word] || word;

export default translate;
