import React from 'react';
import propTypes from 'prop-types';

import ContentBox from './ContentBox';

function Dialog({ open, children }) {
  if (!open) return null;
  return (
    <div className="block-content always dialog">
      <ContentBox>
        {children}
      </ContentBox>
    </div>
  );
}

Dialog.propTypes = {
  open: propTypes.bool,
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.arrayOf(propTypes.node),
  ]),
};
Dialog.defaultProps = {
  open: false,
  children: null,
};

export default Dialog;
