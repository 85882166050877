import React from 'react';
import Grid from '@mui/material/Grid';

import Overview from './Overview';

function Termine() {
  return (
    <Overview
      xs={12}
      md={6}
      title="Bevorstehende Termine"
      tableName="Einladungen"
      tableFilter={({ meeting_time: time }) => {
        const then = new Date(time);
        const now = new Date();
        return then > now;
      }}
      tableSort={(a, b) => {
        const timeA = new Date(a.meeting_time);
        const timeB = new Date(b.meeting_time);
        if (!timeA && timeB) return -1;
        if (timeA && !timeB) return 1;
        if (timeA > timeB) return 1;
        if (timeB < timeA) return -1;
        return 0;
      }}
      columnIDs={[
        'kunde_id',
        'mitarbeiter_id',
        'meeting_time',
      ]}
    />
  );
}

function Auswertungen() {
  return (
    <Overview
      xs={12}
      md={6}
      title="Auswertungen"
      tableName="Auswertungen"
      tableSort={(a, b) => {
        const statusA = a.auswertung_status;
        const statusB = b.auswertung_status;
        if (statusA > statusB) return 1;
        if (statusA < statusB) return -1;
        const fallback = 'January 1, 100 12:00:00';
        const timeA = new Date(a.last_updated || fallback);
        const timeB = new Date(b.last_updated || fallback);
        if (timeA > timeB) return -1;
        if (timeB < timeA) return 1;
        return 0;
      }}
      columnIDs={[
        'kunde_id',
        'mitarbeiter_id',
        'auswertung_status',
      ]}
    />
  );
}

function Kunden() {
  return (
    <Overview
      xs={12}
      md={6}
      title="Kunden"
      tableName="Kunden"
      columnIDs={[
        'vorname',
        'name',
        'unternehmen_id',
      ]}
    />
  );
}

function Artikel() {
  return (
    <Overview
      xs={12}
      md={6}
      title="Artikel"
      tableName="Artikel"
      columnIDs={[
        'artikelbezeichnung',
        'hersteller_id',
        'produktgruppe',
      ]}
    />
  );
}

function KundenCount() {
  return (
    <Overview
      xs={12}
      md={4}
      title="Kunden"
      tableName="Kunden"
      countOnly
    />
  );
}

function FertigeAuswertungen() {
  return (
    <Overview
      xs={12}
      md={4}
      title="Fertige Auswertungen"
      tableName="Auswertungen"
      countOnly
      maxRows={undefined}
      tableFilter={({ auswertung_status: status }) => status === 'Fertig'}
    />
  );
}

function ArtikelCount() {
  return (
    <Overview
      xs={12}
      md={4}
      title="Artikel"
      tableName="Artikel"
      countOnly
      maxRows={undefined}
    />
  );
}

function Dashboard() {
  return (
    <Grid container sx={{ maxWidth: '100%' }} spacing={2}>
      <KundenCount />
      <FertigeAuswertungen />
      <ArtikelCount />
      <Termine />
      <Auswertungen />
      <Kunden />
      <Artikel />
    </Grid>
  );
}

export default Dashboard;
