import React from 'react';
import propTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';

import { Button } from '../Common';

const LABEL = 'CSV herunterladen';
function CSVDownloadButton({ table, CSV }) {
  const link = window.URL.createObjectURL(CSV);
  const filename = `${table}.csv`;
  return (
    <a href={link} download={filename}>
      <Button>
        <DownloadIcon />
        {LABEL}
      </Button>
    </a>
  );
}
CSVDownloadButton.propTypes = {
  table: propTypes.string.isRequired,
  CSV: propTypes.instanceOf(Object).isRequired,
};

export default CSVDownloadButton;
