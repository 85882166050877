const ROUTES = {
  reset: '/reset',
  signIn: '/signin',
  signUp: '/signup',
  permissions: '/permissions',
  unternehmen: '/unternehmen',
  mitarbeiter: '/mitarbeiter',
  kunden: '/kunden',
  krankenkassen: '/krankenkassen',
  hersteller: '/hersteller',
  artikel: '/artikel',
  artikeloptionen: '/artikeloptionen',
  preisliste: '/preisliste',
  einstellungen: '/einstellungen',
  einladungen: '/einladungen',
  style: '/style',
  auswertungen: '/auswertungen',
  otoplastiken: '/otoplastiken',
  statusstufen: '/statusstufen',
};

module.exports = ROUTES;
